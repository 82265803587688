
import { useRoute } from 'vue-router';
import { defineComponent, onMounted, ref, computed } from 'vue';
import ApiService from '@/core/services/ApiService';
import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
import moment from 'moment';

export default defineComponent({
	name: 'user-details',
	components: {
		// EditMediaModal
	},
	setup() {
		const route = useRoute();
		const mediaDetails = ref({});

		const getMediaDetails = () => {
			ApiService.get(`CmsMedia/${route.params.id}`)
				.then(({ data }) => {
					mediaDetails.value = data;
				})
				.catch(({ response }) => {
					console.log('error', response);
				});
		};

		onMounted(() => {
			setCurrentPageBreadcrumbs('CMS Media Details', ['CMS Media']);
			getMediaDetails();
		});

		return {
			moment,
			mediaDetails,
			getMediaDetails
		};
	}
});
